"use client";
import { Box, Heading, Text, Image } from "@chakra-ui/react";

const ErrorPage = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bg="#07183b"
      color="#FFFFFF"
      fontFamily="Arial, sans-serif"
      textAlign="center"
      flexDirection="column"
    >
      <Image
        maxH={"300px"}
        maxW={"300px"}
        src="/public/logo_wo_bg.png"
        alt="404 Image"
        w="auto"
        h="auto"
      />

      <Box>
        <Heading as="h1" size="2xl" mb={4} fontWeight="bold">
          Ой! Что то пошло не так.
        </Heading>
        <Text fontSize="lg" fontWeight="medium" mb={6}>
          Сожалеем, но страница не работает.
        </Text>
        <Text fontSize="sm">
          Пожалуйста, проверьте URL-адрес или вернитесь к{" "}
          <Box as="a" href="/" color="#FFFFFF" textDecoration="underline">
            домашней странице.
          </Box>
          .
        </Text>
      </Box>
    </Box>
  );
};

export default ErrorPage;
